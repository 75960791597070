<template>
    <page :heading="image.originalFilename">
        <template v-slot:header-action>
            <v-button @click="deleteImage">
                Delete Image
            </v-button>
        </template>

        <div class="image-show">
            <a :href="image.url" target="_blank">
                <img :src="image.url" class="image-show__image" />
            </a>
        </div>

        <template v-slot:sidebar>
            <exif-table :exif="image.exif" />
        </template>
    </page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Page from '../../../layouts/Page.vue';
import store from '../../../store';
import ExifTable from '../../../components/Presentation/ExifTable.vue';
import VButton from '../../../components/Action/VButton.vue';

export default {
    components: { VButton, ExifTable, Page },
    async beforeRouteEnter({ params }) {
        const { albumId, imageId } = params;

        await store.dispatch('image/fetchImage', { albumId, imageId });
    },
    computed: {
        ...mapState('image', ['image']),
    },
    methods: {
        ...mapActions('image', { deleteImageAction: 'deleteImage' }),
        async deleteImage() {
            const confirmed = confirm('Delete this image?'); // eslint-disable-line

            if (confirmed) {
                const { albumId, imageId } = this.$route.params;

                const { data: { success } } = await this.deleteImageAction({ albumId, imageId });

                if (success) {
                    await this.$router.back();
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.image-show {
    margin-bottom: 8rem;

    &__image {
        vertical-align: bottom;
    }
}
</style>
